import React, { useEffect, useContext, useState } from "react";
import { FirebaseContext } from "../Firebase";
import AuthUserContext from "./context";
import { AUTH_USER } from "../../constants/keys";
import useGlobal from "../../store";

const withAuthentication = Component => props => {
  const [clinic, setClinic] = useGlobal(
    state => state.clinic,
    actions => actions.setClinic
  );
  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem(AUTH_USER))
  );
  const firebase = useContext(FirebaseContext);

  useEffect(
    () => {
      firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem(AUTH_USER, JSON.stringify(authUser));
          setAuthUser(authUser);
        },
        () => {
          if(clinic) {
            setClinic(null);
          }
          localStorage.removeItem(AUTH_USER);
          setAuthUser(null);
        }
      )},
    [firebase, clinic, setClinic]
  );

  return (
    <AuthUserContext.Provider value={authUser}>
      <Component {...props} />
    </AuthUserContext.Provider>
  );
};

export default withAuthentication;
