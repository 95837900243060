import React, { useContext } from "react";
import "./App.css";
import {
  HashRouter,
  Route,
  Switch
} from "react-router-dom";
import { AuthUserContext, withAuthentication } from "./components/Session";
import * as ROLES from "./constants/roles";
import * as ROUTES from "./constants/routes";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import { IUser } from "./components/Patients";

const App: React.FC = () => {
  const authUser: IUser = useContext(AuthUserContext);
  return (
    <HashRouter>
      <Switch>
        <Route path={ROUTES.SIGN_UP} exact component={SignUp}/>
        <Route path={ROUTES.SIGN_IN} exact component={SignIn}/>
        <Route path={ROUTES.DASHBOARD} exact component={authUser?.roles[ROLES.ADMIN] ? Admin : Dashboard}/>
        <Route path={ROUTES.ADMIN} exact component={Admin}/>
      </Switch>
    </HashRouter>
  );
};

export default withAuthentication(App);
