import React from "react";
import Icon from "../Icon";
import { IMessages } from "../../actions";

export const Confirm: React.FC<IConfirm> = ({ data }) => (
  <div className="whitespace-pre-wrap">{data.message}</div>
);

const Modal: React.FC<IModal> = ({ visible, title, className, onClose, onAction, actionEnabled, children }) =>
  visible ? (
    <>
      <div className="absolute w-screen h-full bg-black opacity-50" />
      <div className="absolute w-screen h-screen z-10">
        <div className="bg-white rounded md:max-w-lg lg:max-w-2xl w-10/12 border shadow-lg m-auto md:mt-32 mt-16 font-Olight">
          <div className="rounded-t bg-primary">
            <div className="relative py-2 px-6 flex">
              <span className="uppercase font-semibold text-white md:text-base text-sm">{title}</span>
              <div
                onClick={onClose}
                className="absolute right-0 top-0 -mr-2 -mt-2 border cursor-pointer shadow-lg bg-white z-10 p-1 rounded-full p-2"
              >
                <Icon type={"close"} className="w-2 h-2"/>
              </div>
            </div>
          </div>
          <div className={`rounded-lg p-2 m-1 mb-0 ${className}`}>{children}</div>
          <div className="p-2 flex justify-end rounded-b">
            <button onClick={onClose} className="btn w-24">
              Cancel
            </button>
            <button disabled={!actionEnabled} onClick={onAction} className="btn w-24">
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  ) : null;

interface IConfirm {
  data: IMessages;
  onAction: (data: object) => void;
}

interface IModal {
  visible: boolean;
  title: string;
  className?: string;
  onAction?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  actionEnabled: boolean;
}

export interface IModalType {
  title: string;
  content: React.FC<any>;
  action: () => void
}

export default Modal;
