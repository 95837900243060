import React, { ChangeEvent, useContext } from "react";
import logo from "../assets/OVERTURE_LOGO_06.svg";
import ovum from "../assets/ovum.svg";
import { NavLink, useHistory } from "react-router-dom";
import { AuthUserContext } from "../components/Session";
import * as ROUTES from "../constants/routes";
import Firebase, { FirebaseContext } from "../components/Firebase";
import useForm from "../utils/useForm";
import { IUser } from "../components/Patients";
import { PASSWORD_LENGTH, WRONG_FORMAT_EMAIL, WRONG_FORMAT_PREFIX, WRONG_PASSWORD } from "../assets/strings";

const EMBRYO_COUNT_INIT = 100;

const SignIn: React.FC = () => {
  const authUser: IUser = useContext(AuthUserContext);
  const firebase: Firebase = useContext(FirebaseContext);
  const [clinicData, dispatch] = useForm();
  const history = useHistory();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = [];
    if (!/\S+@\S+\.\S+/.test(clinicData.email)) {
      errors.push(WRONG_FORMAT_EMAIL);
    }
    if (clinicData.password !== clinicData.passwordRep) {
      errors.push(WRONG_PASSWORD);
    }
    if (clinicData.password.length < 6) {
      errors.push(PASSWORD_LENGTH);
    }
    if (clinicData.prefix.length < 2 || clinicData.prefix.length > 4) {
      errors.push(WRONG_FORMAT_PREFIX);
    }
    if (errors.length) {
      dispatch({ type: "update", param: { field: "errors", value: errors } });
      return;
    }

    firebase
      // @ts-ignore
      .doCreateUserWithEmailAndPassword(clinicData.email, clinicData.password)

      .then((auth) => {
        // Create clinic and user
        return (
          firebase
            .clinics()
            // @ts-ignore
            .add({
              embryoCount: EMBRYO_COUNT_INIT,
              // @ts-ignore
              name: clinicData.name,
              // @ts-ignore
              prefix: clinicData.prefix,
              // @ts-ignore
              shortName: clinicData.shortName,
              idAdmin: clinicData.idAdmin,
              // @ts-ignore
              idUser: auth.user.uid,
            })

            .then((refClinic: any) => {
              return (
                firebase
                  // @ts-ignore
                  .users()
                  // @ts-ignore
                  .doc(auth.user.uid)
                  .set({
                    clinicId: refClinic.id,
                    roles: {
                      admin: false,
                      user: true,
                    },
                  })
              );
            })
            .then((ref: any) => {
              firebase.auth
                .signOut()
                .then(() => {
                  firebase.auth
                    .signInWithEmailAndPassword(clinicData.email, clinicData.password)
                    .then(() => history.push(ROUTES.DASHBOARD))
                    .catch((error) => {
                      // An error happened.
                      console.log(error);
                    });
                })
                .catch((error) => {
                  // An error happened.
                  console.log(error);
                });
            })
        );
      })
      .catch((error) => {
        dispatch({ type: "update", param: { field: "errors", value: [error.message] } });
      });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    let value: any = event.target.value;
    const field = event.target.id;
    if (field === "shortName") {
      value = value.replace(/\s/g, "").toLowerCase();
    }
    if (field === "prefix") {
      value = (value.match(/[A-Z]/gi) || []).join("").toUpperCase();
    }
    dispatch({
      type: "update",
      param: { field, value },
    });
  };

  return (
    <div className="flex flex-col m-auto antialiased">
      <div className="bg-gray-500 min-h-screen flex flex-col flex-grow items-center justify-center text-white text-2xl">
        <NavLink to={ROUTES.DASHBOARD}>
          <img src={ovum} className="ovo h-16 w-16" alt="ovum" />
        </NavLink>
        <div className="py-4 font-Olight text-pr">
          New Clinic
          <div className="w-full max-w-xl">
            {!authUser && (
              <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-sm" onSubmit={onSubmit}>
                <div className="flex flex-wrap -mx-3 mb-0 sm:mb-2">
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      value={clinicData?.name || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="name"
                      type="text"
                      maxLength={80}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="shortName"
                    >
                      Sort Name
                    </label>
                    <input
                      value={clinicData?.shortName || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="shortName"
                      type="text"
                      maxLength={20}
                      placeholder="Short Name"
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-0 sm:mb-2">
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="prefix"
                    >
                      Prefix
                    </label>
                    <input
                      value={clinicData?.prefix || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="prefix"
                      type="text"
                      minLength={2}
                      maxLength={4}
                      placeholder="Prefix"
                      required
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      value={clinicData?.email || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="email"
                      type="text"
                      maxLength={80}
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-0 sm:mb-2">
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      value={clinicData?.password || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="password"
                      type="password"
                      maxLength={8}
                      placeholder="Password (min 6 characters)"
                      required
                    />
                  </div>
                  <div className="w-full px-3 mb-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="passwordRep"
                    >
                      Repeat Password
                    </label>
                    <input
                      value={clinicData?.passwordRep || ""}
                      onChange={onChange}
                      className={`text-param`}
                      id="passwordRep"
                      type="password"
                      maxLength={8}
                      placeholder="Repeat Password"
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-0 sm:mb-2">
                  <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="idAdmin"
                    >
                      Region
                    </label>
                    <select
                      value={clinicData?.idAdmin || ""}
                      onChange={onChange}
                      className="select-param"
                      id="idAdmin"
                      required
                    >
                      <option value={""}>Select Region</option>
                      <option value={"XdIfjxwzKqMICIjgMcQopCFl95O2"}>{"USA"}</option>
                      <option value={"XS0Ynhkz4Ph0RfXpWu0aicFMuHx2"}>{"Worldwide"}</option>
                      {/*<option value={"H5NfB3aO7XcLktvVWeiTElv5beA2"}>{"TEST"}</option>*/}
                    </select>
                  </div>
                </div>

                <div className="text-right mt-6">
                  <button
                    className="btn bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    onClick={() => history.push(ROUTES.SIGN_IN)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Sign Up
                  </button>
                </div>
                {clinicData?.errors && (
                  <div className="text-red-500 text-xs italic mt-2 whitespace-pre-wrap">
                    {clinicData.errors.map((error: string, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                  </div>
                )}
              </form>
            )}
            <p className="text-center text-white text-xs">&copy;2021 Overture Life. All rights reserved.</p>
          </div>
        </div>
      </div>
      <footer>
        <img
          src={logo}
          className="h-16 absolute right-0 bottom-0"
          alt="logo"
          onClick={() => history.push(ROUTES.RESET)}
        />
      </footer>
    </div>
  );
};

interface State {
  email: string;
  password: string;
  error: any;
}

interface IFieldValue {
  field: string;
  value: string | object;
}

export default SignIn;
