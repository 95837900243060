import { REQUIRED_FIELD, WRONG_FORMAT_DATE } from "../assets/strings";
import { IMessages } from "./index";

export const setPatient = (store: any, patient: object) => {
  store.setState({ patient });
};

// @ts-ignore
export const validatePatient: IMessages = (patient: IMessages) => {
  const messages: IMessages = {};
  if (!patient.history) {
    messages.history = REQUIRED_FIELD;
  }
  // @ts-ignore
  if (!patient.treatment && patient.treatment !== 0) {
    messages.treatment = REQUIRED_FIELD;
  }
  if (!patient.birthdate) {
    messages.birthdate = REQUIRED_FIELD;
  } else if (!patient.birthdate.match(/^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/)) {
    messages.birthdate = WRONG_FORMAT_DATE;
  }
  return messages;
};
