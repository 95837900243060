import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "../actions";
import { IClinic } from "../components/Clinics";
import { IPatient } from "../components/Patients";
import { IEmbryo } from "../components/Embryos";
import { DashboardActions } from "../actions";

export type DashboardState = {
  clinic: IClinic|undefined;
  patient: IPatient|undefined;
  embryo: IEmbryo|undefined;
  embryoCount: number|undefined;
};

export const initialState: DashboardState = {
  clinic: undefined,
  patient: undefined,
  embryo: undefined,
  embryoCount: undefined
};

const useGlobal = useGlobalHook<DashboardState, DashboardActions>(React, initialState, actions);

export default useGlobal;
