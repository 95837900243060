import { validatePatient } from "./patient";
import { validateEmbryo } from "./embryo";
import { validateClinic } from "./clinic";
import { IState } from "../components/Patients/actions";
import { IClinic } from "../components/Clinics";
export { setPatient } from "./patient";
export { setEmbryo } from "./embryo";
export { setEmbryoCount } from "./embryo";
export { setClinic } from "./clinic";

export interface IMessages {
  [key: string]: string;
}

export type DashboardActions = {
  setClinic: (clinic: IClinic) => void;
  setEmbryo: (embryo: IState|undefined) => void;
  setEmbryoCount: (embryo: number|undefined) => void;
  validate: (entity: string) => IMessages|null;
  setPatient: (patient: IState|undefined) => void;
};

export const validate = (store: any, entity: string) => {
  let messages: IMessages = {};
  if(entity === "patient") {
    if (!store.state.patient) {
      return messages;
    }
    // @ts-ignore
    messages = validatePatient(store.state.patient);
  } else if(entity === "embryo") {
    if (!store.state.embryo) {
      return messages;
    }
    // @ts-ignore
    messages = validateEmbryo(store.state.embryo);
  } else if(entity === "clinic") {
    if (!store.state.clinic) {
      return messages;
    }
    // @ts-ignore
    messages = validateClinic(store.state.clinic);
  }
  return !Object.keys(messages).length ? null : messages;
};
