import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import useGlobal from "../../store";
import useForm from "../../utils/useForm";
import { EmbryoStatus, IEmbryo, Ongoing, OngoingData, PGT, PGTData, StatusData, ICM, ICMData, TE, TEData } from "../Embryos";
import zeroPadding from "../../utils/zeroPadding";
import { TreatmentType } from "../Patients";
import strings from "../../constants/strings";
import { IMessages } from "../../actions";

interface IFormEmbryoParams {
  data: IEmbryo;
  onAction: (data: object) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const Embryo: React.FC<IFormEmbryoParams> = ({ data }) => {
  const [embryoData, dispatch] = useForm(data);
  const [clinic] = useGlobal(state => state.clinic);
  const [validateData, setValidateData] = useState<IMessages | null>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [embryo, setEmbryo] = useGlobal(
    state => state.embryo,
    actions => actions.setEmbryo
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, validate] = useGlobal(
    state => state,
    actions => actions.validate
  );
  const [embryoCount] = useGlobal(state => state.embryoCount);
  const [statusText, setStatusText] = useState("");
  const [onGoingText, setOngoingText] = useState("");
  const [embryoCodeText, setembryoCodeText] = useState("");

  const updateEmbryo = () => {
    setEmbryo(embryoData);
    if(embryoData !== undefined) {
      if (embryoData.status !== "") {
        setStatusText(getText(strings.embryos.status, embryoData.status));
      }
      if(embryoData.ongoing !== "") {
        setOngoingText(getText(strings.embryos.onGoing, embryoData.ongoing));
      }
    }
  };

  const updateCount = () => {
    !data &&
    embryoCount &&
    dispatch({
      type: "update",
      param: { field: "olCode", value: formatOlCode(embryoCount, "B") }
    });
  };

  const embryoCallback = useCallback(updateEmbryo, [embryoData]);

  const countCallback = useCallback(updateCount, [embryoCount]);

  useEffect(() => {
    embryoCallback();
    embryoData && setValidateData(validate("embryo"));
  }, [embryoCallback, embryoData, validate]);

  useEffect(() => {
    countCallback();
  }, [countCallback, embryoCount]);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any>) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const field = event.target.id;
    dispatch({
      type: "update",
      param: { field, value }
    });
    if (field === "embryoCode") {
      if (!data && embryoCount) {
        dispatch({
          type: "update",
          param: { field: "olCode", value: formatOlCode(embryoCount, value ? "" : "B") }
        });
      } else {
        if (value === "") {
          setembryoCodeText(strings.embryos.embryoCode.text)
        }
      }
    } else if (field === "pgt" && PGT.ANEUPLOID === +value) {
      dispatch({
        type: "update",
        param: { field: "status", value: "" }
      });
      dispatch({
        type: "update",
        param: { field: "ongoing", value: "" }
      });
    } else if (field === "status") {
      if (value !== "") {
        if ( EmbryoStatus.NON_IMPLANTED === +value ) {
          setStatusText(getText(strings.embryos.status, +value));
          dispatch({
            type: "update",
            param: { field: "ongoing", value: "" }
          });
        } else {
          setStatusText(getText(strings.embryos.status, +value));
        }
      } else {
        setStatusText("");
      }
    } else if (field === "ongoing") {
      if( value !== "" ) {
        setOngoingText(getText(strings.embryos.onGoing, +value));
      } else {
        setOngoingText("");
      }
    }
  };

  const formatOlCode = (count: number, sufix: string) => {
    const countFormat = zeroPadding(count, 3);
    return countFormat ? `${clinic?.prefix}${countFormat}${sufix}` : "";
  };

  const getText = (array: any, key: any) => {
    if(array[key] !== undefined) {
      return array[key].text;
    }
  };

  return (
    <form className="w-full">
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="olCode">
            OL Code
          </label>
          <input
            value={embryoData?.olCode || "Calculating..."}
            className={`text-param`}
            id="olCode"
            type="text"
            placeholder="Ol Code"
            required
            disabled
          />
        </div>
        <div className="w-full sm:w-1/2 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="embryoCode">
            Embryo Code
          </label>
          <input
            value={embryoData?.embryoCode || ""}
            onChange={onChange}
            className={`text-param`}
            id="embryoCode"
            type="text"
            placeholder="Embryo Code"
          />
          <div className="w-full mt-1 embryo-text-container text-red-500 text-s">
            {embryoCodeText}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <fieldset className="flex">
            <div className="w-1/2 pr-1">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="olCode">
                ICM
              </label>
              <div className="relative" style={{maxHeight: "46px"}}>
                <select
                  value={embryoData ? (embryoData.icm as ICM) : ""}
                  onChange={onChange}
                  className={`select-param truncate${validateData?.icm ? " error" : ""}`}
                  id="icm"
                  disabled={!embryoData?.embryoCode}
                >
                  <option value={""}>Select ICM</option>
                  <option value={ICM.A}>
                    {ICMData[ICM[ICM.A] as string].text}
                  </option>
                  <option value={ICM.B}>
                    {ICMData[ICM[ICM.B] as string].text}
                  </option>
                  <option value={ICM.C}>
                    {ICMData[ICM[ICM.C] as string].text}
                  </option>
                  <option value={ICM.D}>
                   {ICMData[ICM[ICM.D] as string].text}
                  </option>
                </select>
                <p className="text-red-500 text-xs italic">{validateData?.icm}</p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-1">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="embryoCode">
                TE
              </label>
              <div className="relative" style={{maxHeight: "46px"}}>
                <select
                  value={embryoData ? (embryoData.te as TE) : ""}
                  onChange={onChange}
                  className={`select-param truncate${validateData?.te ? " error" : ""}`}
                  id="te"
                  disabled={!embryoData?.embryoCode}
                >
                  <option value={""}>Select TE</option>
                  <option value={TE.A}>
                    {TEData[TE[TE.A] as string].text}
                  </option>
                  <option value={TE.B}>
                    {TEData[TE[TE.B] as string].text}
                  </option>
                  <option value={TE.C}>
                  {TEData[TE[TE.C] as string].text}
                  </option>
                  <option value={TE.D}>
                  {TEData[TE[TE.D] as string].text}
                  </option>
                </select>
                <p className="text-red-500 text-xs italic">{validateData?.te}</p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        {/* <div className="w-full sm:w-1/2 px-3"></div> */}
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="status">
            Status
          </label>
          <div className="relative">
            <select
              value={embryoData ? (embryoData.status as EmbryoStatus) : ""}
              onChange={onChange}
              className={`select-param`}
              id="status"
              disabled={!embryoData?.embryoCode}
            >
              <option value={""}>Select Status</option>
              <option value={EmbryoStatus.TRANSFERRED}>
                {StatusData[EmbryoStatus[EmbryoStatus.TRANSFERRED] as string].text}
              </option>
              <option value={EmbryoStatus.CRYOPRESERVED}>
                {StatusData[EmbryoStatus[EmbryoStatus.CRYOPRESERVED] as string].text}
              </option>
              <option value={EmbryoStatus.IMPLANTED}>
                {StatusData[EmbryoStatus[EmbryoStatus.IMPLANTED] as string].text}
              </option>
              <option value={EmbryoStatus.NON_IMPLANTED}>
                {StatusData[EmbryoStatus[EmbryoStatus.NON_IMPLANTED] as string].text}
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div className="w-full mt-1 embryo-text-container text-blue-500 text-s">
            {statusText}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="pgt">
            PGT
          </label>
          <div className="relative">
            <select
              value={embryoData ? (embryoData.pgt as TreatmentType) : ""}
              onChange={onChange}
              className={`select-param`}
              id="pgt"
              disabled={!embryoData?.embryoCode}
            >
              <option value={""}>Select PGT</option>
              <option value={PGT.ANEUPLOID}>{PGTData[PGT[PGT.ANEUPLOID] as string].text}</option>
              <option value={PGT.EUPLOID}>{PGTData[PGT[PGT.EUPLOID] as string].text}</option>
              <option value={PGT.NON_TRANSFERABLE_MOSAIC}>
                {PGTData[PGT[PGT.NON_TRANSFERABLE_MOSAIC] as string].text}
              </option>
              <option value={PGT.TRANSFERABLE_MOSAIC}>{PGTData[PGT[PGT.TRANSFERABLE_MOSAIC] as string].text}</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>      
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ongoing">
            Ongoing
          </label>
          <div className="relative">
            <select
              value={embryoData ? (embryoData.ongoing as Ongoing) : ""}
              onChange={onChange}
              className={`select-param`}
              id="ongoing"
              disabled={!embryoData?.embryoCode}
            >
              <option value={""}>Select Ongoing</option>
              <option value={Ongoing.PREGNANCY}>{OngoingData[Ongoing[Ongoing.PREGNANCY] as string].text}</option>
              <option value={Ongoing.NON_PREGNANCY}>
                {OngoingData[Ongoing[Ongoing.NON_PREGNANCY] as string].text}
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div className="w-full mt-1 embryo-text-container text-green-500 text-s">
            {onGoingText}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="otherMorphology">
            Other morphology score
          </label>
          <input
            value={embryoData?.otherMorphology || ""}
            onChange={onChange}
            className={`text-param`}
            id="otherMorphology"
            name="otherMorphology"
            type="text"
            maxLength={10}
            disabled={!embryoData?.embryoCode}
          />
        </div>
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="biopsyDay">
            Biopsy Day
          </label>
          <input
            value={embryoData?.biopsyDay || ""}
            className={`text-param`}
            onChange={onChange}
            id="biopsyDay"
            name="biopsyDay"
            type="number"
            min="0"
            max="9"
            disabled={!embryoData?.embryoCode}
          />
        </div>
      </div>
    </form>
  );
};
