import { useEffect, useReducer } from "react";
import { IFieldValue } from "../components/Patients/actions";

export type FormAction =
  | { type: "update"; param: IFieldValue }
  | { type: "message"; text: string }
  | { type: "error"; text: string }
  | { type: "reset" }
  | { type: "create"; callback?: () => void }
  | { type: "modify"; callback?: () => void }
  | { type: "delete"; callback?: () => void };

function formReducer(state: any, action: FormAction) {
  let newState;
  switch (action.type) {
    case "update":
      newState = {
        ...state,
        [action.param.field]: action.param.value
      };
      break;
    case "reset":
      newState = {};
      break;
    default:
      console.log("No action for ", action.type);
  }
  return newState;
}

const formatData = (data?: object) => {
  if(!data) {
    return undefined;
  }
  const format = {...data};
  for(let p in data) {
    // @ts-ignore
    if(p === "birthdate" && format[p].seconds) {
      // @ts-ignore
      format[p] = new Date(format[p].seconds * 1000).toISOString().split("T")[0];
    }
  }
  return format;
};

const useForm = (initialData?: object) => {
  const [formData, dispatch] = useReducer(formReducer, formatData(initialData));

  useEffect(() => {
    // console.log(formData);
  }, [formData]);
  return [formData, dispatch];
};

export default useForm;
