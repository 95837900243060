import React, { useContext, useEffect, useReducer } from "react";
import logo from "../assets/OVERTURE_LOGO_06.svg";
import ovum from "../assets/ovum.svg";
import { NavLink, useHistory } from "react-router-dom";
import { AuthUserContext } from "../components/Session";
import * as ROUTES from "../constants/routes";
import Firebase, { FirebaseContext } from "../components/Firebase";

const reducer = (state: State, {field, value}: IFieldValue): State => ({
  ...state,
  [field]: value
});

const initialState = {
  email: "",
  password: "",
  error: null
};

const SignIn: React.FC = () => {
  const authUser = useContext(AuthUserContext);
  const firebase: Firebase = useContext(FirebaseContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const { email, password, error } = state;

  useEffect(() => {
    if(!authUser) {
      // TODO: Sign Up user
    } else {
      history.push(ROUTES.DASHBOARD);
    }
  }, [authUser, history]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser: object) => {
        history.push(ROUTES.DASHBOARD);
      })
      .catch((error: object) => {
        dispatch({ field: "error", value: error });
      });
    event.preventDefault();
  };

  const onChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    // TODO: Fix client validation
    // dispatch({field: "errors", value: validate({email, password})});
    dispatch({ field: event.target.id, value: event.target.value });
  };

  return (
    <div className="flex flex-col m-auto antialiased">
      <div className="bg-gray-500 min-h-screen flex flex-col flex-grow items-center justify-center text-white text-2xl">
        <NavLink to={ROUTES.DASHBOARD}>
          <img src={ovum} className="ovo h-16 w-16" alt="ovum" />
        </NavLink>
        <div className="py-4 font-Olight text-pr">
          Overture Metabolomics
          <div className="w-full max-w-xs">
            {!authUser && (
              <form
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-sm"
                onSubmit={onSubmit}
                onBlur={onChange}
                onChange={onChange}
              >
                <div className="mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="username"
                  >
                    Email
                  </label>
                  <input
                    className="text-param"
                    id="email"
                    type="text"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    className="text-param"
                    id="password"
                    type="password"
                  />
                </div>
                <div className="flex justify-between">
                  <a href={`#${ROUTES.SIGN_UP}`}
                    className="hover:text-blue-700 text-blue-700 align-middle leading-loose"
                  >
                    Sign Up
                  </a>
                  <button
                    className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
                {error && <div className="text-red-500 text-xs italic mt-2">{error.message}</div>}
              </form>
            )}
            <p className="text-center text-white text-xs">
              &copy;2021 Overture Life. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <footer>
        <img src={logo} className="h-16 absolute right-0 bottom-0" alt="logo" onClick={() => history.push(ROUTES.RESET)} />
      </footer>
    </div>
  );
};

interface State {
  email: string;
  password: string;
  error: any;
}

interface IFieldValue {
  field: string;
  value: string|object;
}

export default SignIn;
