export default {
    embryos: {
        status: [
            {name: "transferred", id: 0, text: ""},
            {name: "implanted", id: 1, text: "Elevated bHCG level detected"},
            {name: "non-implanted", id: 2, text: ""},
            {name: "cryopreserved", id: 3, text: ""},
        ],
        onGoing: [
            {name: "pregnancy", id: 0, text: "Fetal heartbeat is observed"},
            {name: "non-pregnancy", id: 1, text: ""},
        ],
        embryoCode:
            {text: "Please insert the embryo code"}
    },
};
