import React, { useContext, useState } from "react";
import Firebase, { FirebaseContext } from "../components/Firebase";
import { AuthUserContext, withAuthorization } from "../components/Session";
import Header from "../components/Header";
import * as ROLES from "../constants/roles";
import Clinics, { IClinic } from "../components/Clinics";
import { IUser } from "../components/Patients";
import Embryos from "../components/Embryos";
import Modal, { Confirm, IModalType } from "../components/Modal";
import { Clinic } from "../components/Clinics/actions";
import { IState } from "../components/Patients/actions";
import useGlobal from "../store";

type ModalType = "editClinic" | "deleteClinic";

const Admin: React.FC = () => {
  const authUser: IUser = useContext(AuthUserContext);
  const firebase: Firebase = useContext(FirebaseContext);
  const [selectedClinic, setSelectedClinic] = useState<IClinic>();
  const [timestampClinic, setTimestampClinic] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>("editClinic");
  const [modalContentData, setModalContentData] = useState<IState>({});
  const [clinic, validate] = useGlobal(
    (state) => state.clinic,
    (actions) => actions.validate
  );

  const onSelectClinic = (clinic: IClinic | undefined) => {
    setSelectedClinic(clinic);
  };

  const showEditClinic = () => {
    setModalType("editClinic");
    updateModalContentData("editClinic");
    setShowModal(true);
  };

  const showDeleteClinic = () => {
    setModalType("deleteClinic");
    updateModalContentData("deleteClinic");
    setShowModal(true);
  };

  const dismissModal = () => {
    setShowModal(false);
  };

  const doModifyClinic = () => {
    if (!clinic) {
      return;
    }
    delete clinic.uid;
    firebase
      .clinics()
      // @ts-ignore
      .doc(selectedClinic.uid)
      // @ts-ignore
      .update({
        ...clinic,
      })
      .then(function (docRef: any) {
        setSelectedClinic(undefined);
        setTimestampClinic(new Date().getTime());
      })
      .catch(function (error: any) {
        console.error("Error updating document: ", error);
      });
    setShowModal(false);
  };

  const doDeleteClinic = () => {
    // FIXME: Auth user must be deleted manually
    selectedClinic &&
      firebase
        .users()
        .doc(selectedClinic.idUser)
        .delete()
        .then(() =>
          firebase
            .clinics()
            // @ts-ignore
            .doc(selectedClinic.uid)
            .delete()
            .then(function (docRef: any) {
              setSelectedClinic(undefined);
              setTimestampClinic(new Date().getTime());
            })
        )
        .catch((error: any) => {
          console.error("Error deleting document: ", error);
        });
    setShowModal(false);
  };

  const ModalTypes: { [id: string]: IModalType } = {
    editClinic: { title: "Modify Clinic", content: Clinic, action: doModifyClinic },
    deleteClinic: { title: "Delete Clinic", content: Confirm, action: doDeleteClinic },
  };

  const ModalContent: React.FC<any> = ModalTypes[modalType].content;

  const updateModalContentData: any = (type: ModalType) => {
    let data: any;
    switch (type) {
      case "editClinic":
        data = selectedClinic;
        break;
      case "deleteClinic":
        data = {
          message: `Clinic ${selectedClinic?.name} will be deleted.
        Auth User must be removed manually.`,
        };
        break;
      default:
        data = undefined;
    }
    setModalContentData(data);
  };

  const getActionEnabled = () => {
    switch (modalType) {
      case "editClinic":
        return !validate("clinic");
      case "deleteClinic":
        return true;
      default:
        return false;
    }
  };

  const isPrivateData = authUser.roles.user && authUser.roles.admin && selectedClinic?.idAdmin !== authUser.uid;

  return (
    <div className="flex flex-col min-h-screen relative">
      <Header title={"Admin"} />
      <div className="flex flex-grow font-Olight text-gray-700 flex-col p-4 bg-gray-100">
        <Clinics
          onSelectClinic={onSelectClinic}
          selectedClinicId={selectedClinic?.uid}
          showEditClinic={showEditClinic}
          showDeleteClinic={showDeleteClinic}
          timestamp={timestampClinic}
        />
        {selectedClinic ? (
          <Embryos selectedClinic={selectedClinic} isPrivateData={isPrivateData} />
        ) : (
          <div className="text-center">Select Clinic</div>
        )}
      </div>
      <footer className="py-1 px-2 bg-gray-600 font-Olight font-hairline text-white text-right text-xs">
        All rights reserved 2021
      </footer>
      <Modal
        visible={showModal}
        title={ModalTypes[modalType].title}
        onAction={ModalTypes[modalType].action}
        onClose={dismissModal}
        actionEnabled={getActionEnabled()}
      >
        <ModalContent data={modalContentData} />
      </Modal>
    </div>
  );
};

const rule = (authUser: IUser) => !!authUser?.roles[ROLES.ADMIN];

export default withAuthorization(rule)(Admin);
