import React, { ChangeEvent, useCallback, useEffect } from "react";
import useGlobal from "../../store";
import useForm from "../../utils/useForm";
import { IClinic } from "../Clinics";

export interface IFormClinicParams {
  data: IClinic;
  onAction: (data: object) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const Clinic: React.FC<IFormClinicParams> = ({ data }) => {
  const [clinicData, dispatch] = useForm(data);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clinic, setClinic] = useGlobal(
    state => state.clinic,
    actions => actions.setClinic
  );

  const updateClinic = () => setClinic(clinicData);

  const clinicCallback = useCallback(updateClinic, [clinicData]);

  useEffect(() => {
    clinicCallback();
  }, [clinicCallback, clinicData]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: any = event.target.value;
    const field = event.target.id;
    if (field === "prefix") {
      value = (value.match(/[A-Z]/gi) || []).join("").toUpperCase();
    }
    dispatch({
      type: "update",
      param: { field, value }
    });
  };

  return (
    <form className="w-full">
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-full px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            value={clinicData?.name || ""}
            onChange={onChange}
            className={`text-param`}
            id="name"
            type="text"
            placeholder="Name"
            required
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="shortName">
            Sort Name
          </label>
          <input
            value={clinicData?.shortName || ""}
            onChange={onChange}
            className={`text-param`}
            id="shortName"
            type="text"
            placeholder="Short Name"
            required
          />
        </div>
        <div className="w-full sm:w-1/2 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="prefix">
            Prefix
          </label>
          <input
            value={clinicData?.prefix || ""}
            onChange={onChange}
            className={`text-param`}
            id="prefix"
            type="text"
            maxLength={4}
            placeholder="Prefix"
            disabled={!!data}
            required
          />
        </div>
      </div>
    </form>
  );
};
