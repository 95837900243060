import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../Firebase";
import AuthUserContext from "./context";
import * as ROUTES from "../../constants/routes";

const withAuthorization = rule => Component => props => {
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  useEffect(
    () =>
      firebase.onAuthUserListener(
        authUser => {
          if (!authUser || !rule(authUser)) {
            history.replace(ROUTES.SIGN_IN);
          }
        },
        () => history.push(ROUTES.SIGN_IN)
      ),
    [firebase, history]
  );

  return rule(authUser) ? <Component {...props} /> : null;
};

export default withAuthorization;
