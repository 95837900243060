import React, { useCallback, useContext, useEffect, useState } from "react";
import Firebase, { FirebaseContext } from "../Firebase";
import firebase from "firebase/app";
import { IClinic } from "../Clinics";
import { formatPatients, IPatient, TreatmentType } from "../Patients";
import useGlobal from "../../store";
import * as XLSX from "xlsx";
import getAgeFromDate from "../../utils/getAgeFromDate";

// const LIMIT_LIST = 5; // TODO: Paginate

const notAvailable = "NA";

export const PGTData: ITagData = {
  EUPLOID: { text: "Euploid", style: "euploid" },
  NON_TRANSFERABLE_MOSAIC: { text: ">50% Mosaic", style: "non-transferable-mosaic" },
  TRANSFERABLE_MOSAIC: { text: "<50% Mosaic", style: "transferable-mosaic" },
  ANEUPLOID: { text: "Aneuploid", style: "aneuploid" },
};
// TODO: Styles are not defined in CSS
export const ICMData: ITagData = {
  A: { text: "A", style: "icm-A" },
  B: { text: "B", style: "icm-B" },
  C: { text: "C", style: "icm-C" },
  D: { text: "D", style: "icm-D" },
};

export const TEData: ITagData = {
  A: { text: "A", style: "te-A" },
  B: { text: "B", style: "te-B" },
  C: { text: "C", style: "te-C" },
  D: { text: "D", style: "te-D" },
};

export const OngoingData: ITagData = {
  PREGNANCY: { text: "Pregnancy", style: "pregnancy" },
  NON_PREGNANCY: { text: "Non Pregnancy", style: "non-pregnancy" },
};

export const StatusData: ITagData = {
  TRANSFERRED: { text: "Transferred", style: "transferred" },
  IMPLANTED: { text: "Beta hCG positive", style: "implanted" },
  NON_IMPLANTED: { text: "Beta hCG negative", style: "non-implanted" },
  CRYOPRESERVED: { text: "Cryopreserved", style: "cryopreserved" },
};

export enum PGT {
  EUPLOID = 0,
  NON_TRANSFERABLE_MOSAIC = 1,
  TRANSFERABLE_MOSAIC = 2,
  ANEUPLOID = 3,
}

export enum ICM {
  A = 0,
  B = 1,
  C = 2,
  D = 3,
}

export enum TE {
  A = 0,
  B = 1,
  C = 2,
  D = 3,
}

export enum Ongoing {
  PREGNANCY = 0,
  NON_PREGNANCY = 1,
}

export enum EmbryoStatus {
  TRANSFERRED = 0,
  IMPLANTED = 1,
  NON_IMPLANTED = 2,
  CRYOPRESERVED = 3,
}

const Embryos: React.FC<IEmbryoProps> = ({
  selectedClinic,
  selectedPatient,
  selectedEmbryo,
  onSelectEmbryo,
  showCreateEmbryo,
  showEditEmbryo,
  showDeleteEmbryo,
  setPatientHasEmbryos,
  isPrivateData,
  timestamp,
}) => {
  const firebase: Firebase = useContext(FirebaseContext);
  const [embryos, setEmbryos] = useState<IEmbryo[]>([]);
  const [clinic] = useGlobal<IClinic | undefined>((state) => state.clinic);

  const updateEmbryos = () => {
    if (clinic && selectedPatient) {
      // User
      getEmbryosFromPatient().then((snapshot) => {
        setEmbryos(formatEmbryos(snapshot));
        setPatientHasEmbryos(!!snapshot.docs.length);
      });
    } else if (selectedClinic) {
      // Admin
      getEmbryosFromClinic().then((snapshot) => setEmbryos(formatEmbryos(snapshot)));
    }
  };

  const callbackEmbryos = useCallback(updateEmbryos, [selectedClinic, selectedPatient, timestamp]);

  useEffect(() => {
    callbackEmbryos();
  }, [selectedPatient, timestamp, clinic, callbackEmbryos]);

  const downloadClinicExcel = () => {
    const filename = `${selectedClinic?.shortName}_${new Date().getTime()}.xlsx`;
    const data = [
      [
        "OL CODE",
        "EMBRYO CODE",
        "SCORE",
        "STATUS",
        "PGT",
        "ONGOING",
        "HISTORY",
        "TREATMENT",
        "AGE",
        "DONOR",
        "OTHER MORPHOLOGY SCORE",
        "BIOPSY DAY",
        "COMMENTS",
      ],
    ];
    isPrivateData && data[0].splice(5, 1);

    const ws_name = selectedClinic?.shortName;
    const wb = XLSX.utils.book_new();

    getPatientsFromClinic()?.then((snapshot: firebase.firestore.QuerySnapshot) => {
      const patients: IPatientDictionary = {};
      formatPatients(snapshot).forEach((patient: IPatient) => {
        patients[patient.uid] = { ...patient };
      });

      const embryosData: any[] = embryos.map((embryo) => {
        const patient = patients[embryo.patientId];
        const statusText = StatusData[EmbryoStatus[embryo.status]]?.text || "";
        const status =
          isPrivateData && statusText.substr(0, statusText.indexOf(" ")) === "Beta" ? notAvailable : statusText;

        return [
          embryo.olCode,
          embryo.embryoCode,
          ICMData[ICM[embryo.icm]]?.text + TEData[TE[embryo.te]]?.text,
          status,
          PGTData[PGT[embryo.pgt]]?.text,
          !isPrivateData ? OngoingData[Ongoing[embryo.ongoing]]?.text : notAvailable,
          patient?.history || "",
          patient ? TreatmentType[patient.treatment] : "",
          patient ? getAgeFromDate(patient.createdAt, patient.birthdate) : "",
          !patient ? "" : patient.donor ? "Yes" : "No",
          embryo.otherMorphology,
          embryo.biopsyDay,
          patient?.comments || "",
        ];
      });

      const ws = XLSX.utils.aoa_to_sheet(data.concat(embryosData));
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    });
  };

  const formatEmbryos = (snapshot: firebase.firestore.QuerySnapshot) => {
    return snapshot.docs.map((embryo: firebase.firestore.DocumentSnapshot) => ({
      ...(embryo.data() as IEmbryo),
      uid: embryo.id,
    }));
  };

  const getEmbryosFromPatient = () => {
    return firebase.db
      .collectionGroup("embryos")
      .where("patientId", "==", selectedPatient?.uid)
      .orderBy("createdAt", "desc")
      .get();
  };

  const getEmbryosFromClinic = () => {
    return firebase.db
      .collectionGroup("embryos")
      .where("clinicId", "==", selectedClinic?.uid)
      .orderBy("createdAt", "desc")
      .get();
  };

  const getPatientsFromClinic = () => {
    return (
      selectedClinic &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(selectedClinic?.uid as string)
        .collection("patients")
        .orderBy("createdAt", "desc")
        .get()
    );
  };

  const getPGT = (embryo: IEmbryo) => {
    const text = PGT[embryo.pgt] ? PGTData[PGT[embryo.pgt]].text : "Pending";
    return PGT[embryo.pgt] ? (
      <span className={`tag tag-${PGTData[PGT[embryo.pgt]].style}`}>
        <span>{text}</span>
      </span>
    ) : (
      text
    );
  };

  const getICM = (embryo: IEmbryo) => {
    const text = ICM[embryo.icm] ? ICMData[ICM[embryo.icm]].text : "";
    return ICM[embryo.icm] ? (
      <span className={`tag-${ICMData[ICM[embryo.icm]].style}`}>
        <span>{text}</span>
      </span>
    ) : (
      text
    );
  };

  const getTE = (embryo: IEmbryo) => {
    const text = TE[embryo.te] ? TEData[TE[embryo.te]].text : "";
    return TE[embryo.te] ? (
      <span className={`tag-${TEData[TE[embryo.te]].style}`}>
        <span>{text}</span>
      </span>
    ) : (
      text
    );
  };

  const getOngoing = (embryo: IEmbryo) => {
    const text =
      embryo.pgt === PGT.ANEUPLOID || embryo.status === EmbryoStatus.NON_IMPLANTED
        ? notAvailable
        : Ongoing[embryo.ongoing]
        ? OngoingData[Ongoing[embryo.ongoing]].text
        : "Pending";
    return Ongoing[embryo.ongoing] ? (
      <span className={`tag tag-${OngoingData[Ongoing[embryo.ongoing]].style}`}>
        <span>{OngoingData[Ongoing[embryo.ongoing]].text}</span>
      </span>
    ) : (
      text
    );
  };

  const getStatus = (embryo: IEmbryo) => {
    const text =
      embryo.pgt === PGT.ANEUPLOID
        ? notAvailable
        : EmbryoStatus[embryo.status]
        ? StatusData[EmbryoStatus[embryo.status]].text
        : "Pending";

    const isPrivateStatus = isPrivateData && text.substr(0, text.indexOf(" ")) === "Beta";

    return EmbryoStatus[embryo.status] ? (
      <span className={`tag tag-${isPrivateStatus ? "na" : StatusData[EmbryoStatus[embryo.status]].style}`}>
        <span>{isPrivateStatus ? notAvailable : text}</span>
      </span>
    ) : (
      <span className={`tag tag-na`}>
        <span>{text}</span>
      </span>
    );
  };

  return (
    <div>
      <div>
        <h2 className="text-xl font-semibold leading-tight">
          Embryos from {selectedPatient?.history || selectedClinic?.name}
        </h2>
      </div>

      <div className="my-2 flex sm:flex-row flex-col justify-between">
        <div className="flex flex-row mb-1 sm:mb-0 mr-1">
          <div className="inline-flex">
            {showCreateEmbryo && (
              <button onClick={showCreateEmbryo} className="btn w-24 ml-1">
                New
              </button>
            )}
            {showEditEmbryo && (
              <button
                onClick={showEditEmbryo}
                disabled={!selectedEmbryo || !selectedEmbryo?.embryoCode}
                className="btn w-24 bg-blue-700 ml-1"
              >
                Edit
              </button>
            )}
            {showDeleteEmbryo && (
              <button onClick={showDeleteEmbryo} disabled={!selectedEmbryo} className="btn w-24 bg-blue-700 ml-1">
                Delete
              </button>
            )}
            {selectedClinic && (
              <button onClick={downloadClinicExcel} disabled={!embryos.length} className="btn w-24 bg-blue-700 ml-1">
                Excel
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="-mx-4 px-4 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="tab-data table w-full patientsRowFull">
            <thead className="table-header-group">
              <tr>
                <th>OL Code</th>
                <th>Embryo Code</th>
                <th>Score</th>
                <th>Status</th>
                <th>PGT</th>
                {!isPrivateData && <th>Ongoing</th>}
              </tr>
            </thead>
            <tbody>
              {embryos.map((embryo, index) => (
                <tr
                  key={`patient_${index}`}
                  className={`${!embryo.embryoCode ? "bg-gray-200 " : ""}${
                    selectedEmbryo?.uid === embryo.uid ? " selected" : ""
                  }`}
                  onClick={onSelectEmbryo && onSelectEmbryo.bind(null, embryo)}
                >
                  <td>
                    <span className="collapseHead hidden">OL Code</span>
                    {embryo.olCode}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Embryo Code</span>
                    {embryo.embryoCode || notAvailable}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Score</span>
                    {!!embryo.embryoCode ? getICM(embryo) : notAvailable}/
                    {!!embryo.embryoCode ? getTE(embryo) : notAvailable}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Status</span>
                    {!!embryo.embryoCode ? getStatus(embryo) : notAvailable}
                  </td>
                  <td>
                    <span className="collapseHead hidden">PGT</span>
                    {!!embryo.embryoCode ? getPGT(embryo) : notAvailable}
                  </td>
                  {!isPrivateData && (
                    <td>
                      <span className="collapseHead hidden">Ongoing</span>
                      {!!embryo.embryoCode ? getOngoing(embryo) : notAvailable}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface ITagData {
  [key: string]: { text: string; style: string };
}

interface IEmbryoProps {
  selectedClinic?: IClinic;
  selectedPatient?: IPatient;
  selectedEmbryo?: IEmbryo;
  onSelectEmbryo?: (embryo: IEmbryo) => void;
  showCreateEmbryo?: () => void;
  showEditEmbryo?: () => void;
  showDeleteEmbryo?: () => void;
  setPatientHasEmbryos?: any;
  isPrivateData?: boolean;
  timestamp?: number;
}

export interface IEmbryo {
  uid: string;
  olCode: string;
  embryoCode: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  ongoing: number;
  icm: number;
  te: number;
  pgt: number;
  status: number;
  patientId: string;
  clinicId: string;
  otherMorphology: string;
  biopsyDay: number;
}

interface IPatientDictionary {
  [key: string]: IPatient;
}

export default Embryos;
