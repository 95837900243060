import { REQUIRED_FIELD } from "../assets/strings";
import { IMessages } from "./index";

export const setEmbryo = (store: any, embryo: object) => {
  store.setState({ embryo });
};

export const setEmbryoCount = (store: any, embryoCount: number) => {
  store.setState({ embryoCount });
};

// @ts-ignore
export const validateEmbryo: IMessages = (embryo: IMessages) => {
  const messages: IMessages = {};
  if (!embryo.olCode) {
    messages.olCode = REQUIRED_FIELD;
  }
  if (!embryo.embryoCode) {
    messages.embryoCode = REQUIRED_FIELD;
  }
  // @ts-ignore
  if (!embryo.pgt && embryo.pgt !== 0) {
    messages.pgt = REQUIRED_FIELD;
  }
  // @ts-ignore
  if (!embryo.status && embryo.status !== 0) {
    messages.status = REQUIRED_FIELD;
  }
  // @ts-ignore
  if (!!embryo.te && !embryo.icm) {
    messages.icm = REQUIRED_FIELD;
  }
  // @ts-ignore
  if (!!embryo.icm && !embryo.te) {
    messages.te = REQUIRED_FIELD;
  }
  return messages;
};
