import React, { useCallback, useContext, useEffect, useState } from "react";
import Firebase, { FirebaseContext } from "../Firebase";
import { IUser } from "../Patients";
import { AuthUserContext } from "../Session";

const Clinics: React.FC<IClinicProps> = ({
  selectedClinicId,
  onSelectClinic,
  showEditClinic,
  showDeleteClinic,
  timestamp,
}) => {
  const firebase: Firebase = useContext(FirebaseContext);
  const authUser: IUser = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clinics, setClinics] = useState<IClinic[]>([]);

  const updateClinics = () => {
    setIsLoading(true);
    onSelectClinic(undefined);
    firebase
      .clinics()
      .get()
      .then((snapShot) => {
        const clinics = snapShot.docs.map<IClinic>((doc) => ({
          ...(doc.data() as IClinic),
          uid: doc.id,
        }));
        let newClinics = [];
        newClinics = [
          ...clinics.filter((clinic) => clinic.idAdmin === authUser.uid),
          ...clinics.filter((clinic) => clinic.idAdmin instanceof Array && clinic.idAdmin.includes(authUser.uid))
        ];
        setClinics(newClinics);
      })
      .finally(() => setIsLoading(false));
  };

  const clinicsCallback = useCallback(updateClinics, [timestamp]);

  useEffect(() => {
    clinicsCallback();
  }, [clinicsCallback]);

  return (
    <div className="">
      <div>
        <h2 className="text-xl font-semibold leading-tight">Clinics</h2>
      </div>

      <div className="my-2 flex sm:flex-row flex-col justify-between">
        <div className="flex flex-row mb-1 sm:mb-0 mr-1">
          <div className="inline-flex">
            <button onClick={showEditClinic} disabled={!selectedClinicId} className="btn w-24 bg-blue-700 ml-1">
              Edit
            </button>
            <button onClick={showDeleteClinic} disabled={!selectedClinicId} className="btn w-24 bg-blue-700 ml-1">
              Delete
            </button>
          </div>
        </div>
      </div>

      <div className="-mx-4 px-4 py-4 overflow-x-auto" style={{ maxHeight: "30rem" }}>
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="tab-data table w-full patientsRowFull">
            <thead className="table-header-group">
              <tr>
                <th>Name</th>
                <th>Short Name</th>
                <th>Prefix</th>
              </tr>
            </thead>
            <tbody>
              {clinics.map((clinic, index) => (
                <tr
                  key={`clinic_${index}`}
                  onClick={onSelectClinic.bind(null, clinic)}
                  className={selectedClinicId === clinic.uid ? "selected" : ""}
                >
                  <td>
                    <span className="collapseHead hidden">History</span>
                    {clinic.name}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Treatment</span>
                    {clinic.shortName}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Age</span>
                    {clinic.prefix}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : clinics.length ? (
            <div>
              {/*<div className="px-2 py-2 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">{`Showing 1 to ${Math.min(
                LIMIT_LIST,
                patients.length
              )} of ${patients.length} Patients`}</span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>*/}
            </div>
          ) : (
            <div className="text-center">No patients</div>
          )}
        </div>
      </div>
    </div>
  );
};

interface IClinicProps {
  selectedClinicId?: string;
  onSelectClinic: (patient: IClinic | undefined) => void;
  showEditClinic?: () => void;
  showDeleteClinic?: () => void;
  timestamp?: number;
}

export type IClinic = {
  email: string;
  idAdmin: string | Array<string>;
  idUser?: string;
  name: string;
  password: string;
  prefix: string;
  shortName: string;
  uid?: string;
};

export default Clinics;
