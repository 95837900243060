const zeroPadding = (value, size) => {
  if(value < 0) {
    return null;
  }
  let str = String(value);
  while (str.length < (size || 2)) {str = "0" + str;}
  return str;
};

export default zeroPadding;
