import React, { useContext, useEffect, useState } from "react";
import { AuthUserContext, withAuthorization } from "../components/Session";
import Header from "../components/Header";
import { IClinic } from "../components/Clinics";
import Patients, { IPatient, IUser } from "../components/Patients";
import Embryos, { IEmbryo } from "../components/Embryos";
import Modal, { IModalType, Confirm } from "../components/Modal";
import { Patient, IState } from "../components/Patients/actions";
import { Embryo } from "../components/Embryos/actions";
import useGlobal from "../store";
import Firebase, { FirebaseContext } from "../components/Firebase";
import zeroPadding from "../utils/zeroPadding";

type ModalType = "createPatient" | "editPatient" | "createEmbryo" | "editEmbryo" | "deletePatient" | "deleteEmbryo";

const Dashboard: React.FC = () => {
  const firebase: Firebase = useContext(FirebaseContext);
  const authUser: IUser = useContext(AuthUserContext);
  const [clinic, setClinic] = useGlobal(
    state => state.clinic,
    actions => actions.setClinic
  );
  const [patient, setPatient] = useGlobal(
    state => state.patient,
    actions => actions.setPatient
  );
  const [embryoCount, setEmbryoCount] = useGlobal(
    state => state.embryoCount,
    actions => actions.setEmbryoCount
  );
  const [embryo, validate] = useGlobal(
    state => state.embryo,
    actions => actions.validate
  );
  const [selectedPatient, setSelectedPatient] = useState<IPatient>(); // TODO: Move to usePatient
  const [selectedEmbryo, setSelectedEmbryo] = useState<IEmbryo>(); // TODO: Move to useEmbryo
  const [timestampPatient, setTimestampPatient] = useState<number>(0);
  const [timestampEmbryo, setTimestampEmbryo] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>("createPatient");
  const [modalContentData, setModalContentData] = useState<IState>({});
  const [patientHasEmbryos, setPatientHasEmbryos] = useState<boolean>(false);

  useEffect(() => {
    if (!clinic) {
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .get()
        // @ts-ignore
        .then(snap =>
          setClinic({
            ...snap.data(),
            uid: snap.id
          } as IClinic)
        );
    }
  }, [authUser.clinicId, clinic, firebase, setClinic]);

  const doCreateEmbryo = () => {
    selectedPatient &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(clinic?.uid)
        .collection("patients")
        .doc(selectedPatient.uid)
        .collection("embryos")
        // @ts-ignore
        .add({
          ...embryo,
          createdAt: firebase.fieldValue.serverTimestamp(),
          patientId: selectedPatient.uid,
          clinicId: authUser.clinicId
        })
        .then(function(docRef: any) {
          // setSelectedEmbryo(undefined);
          setTimestampEmbryo(new Date().getTime());
          setEmbryoCount(undefined);
        })
        .catch(function(error: any) {
          console.error("Error adding document: ", error);
        });
    setShowModal(false);
  };
  const doModifyEmbryo = () => {
    selectedPatient &&
      selectedEmbryo &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .collection("patients")
        .doc(selectedPatient.uid)
        .collection("embryos")
        .doc(selectedEmbryo.uid)
        .update({
          ...embryo
        })
        .then(function(docRef: any) {
          setSelectedEmbryo(undefined);
          setTimestampEmbryo(new Date().getTime());
        })
        .catch(function(error: any) {
          console.error("Error adding document: ", error);
        });
    setShowModal(false);
  };

  const doDeleteEmbryo = () => {
    selectedPatient &&
      selectedEmbryo &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .collection("patients")
        .doc(selectedPatient.uid)
        .collection("embryos")
        .doc(selectedEmbryo.uid)
        .delete()
        .then(function(docRef: any) {
          setSelectedEmbryo(undefined);
          setTimestampEmbryo(new Date().getTime());
        })
        .catch(function(error: any) {
          console.error("Error updating document: ", error);
        });
    setShowModal(false);
  };

  const doCreatePatient = () => {
    patient &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .collection("patients")
        // @ts-ignore
        .add({
          ...patient,
          birthdate: firebase.timestamp.fromDate(new Date(patient.birthdate as string)),
          treatment: +patient.treatment,
          createdAt: firebase.fieldValue.serverTimestamp(),
          clinicId: authUser.clinicId
        })
        .then(function(docRef: any) {
          setSelectedEmbryo(undefined);
          // @ts-ignore
          setSelectedPatient(undefined);
          setPatient(undefined);
          setTimestampPatient(new Date().getTime());
          setTimestampEmbryo(new Date().getTime());
        })
        .catch(function(error: any) {
          console.error("Error adding document: ", error);
        });
    setShowModal(false);
  };

  const doDeletePatient = () => {
    selectedPatient &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .collection("patients")
        .doc(selectedPatient.uid)
        .delete()
        .then(function(docRef: any) {
          setSelectedEmbryo(undefined);
          setSelectedPatient(undefined);
          setPatient(undefined);
          setTimestampPatient(new Date().getTime());
          setTimestampEmbryo(new Date().getTime());
        })
        .catch(function(error: any) {
          console.error("Error updating document: ", error);
        });
    setShowModal(false);
  };

  const doModifyPatient = () => {
    patient &&
      firebase
        .clinics()
        // @ts-ignore
        .doc(authUser.clinicId)
        .collection("patients")
        .doc(patient.uid)
        // @ts-ignore
        .update({
          ...patient,
          // @ts-ignore
          birthdate: firebase.timestamp.fromDate(new Date(patient.birthdate)),
          treatment: +patient.treatment
        })
        .then(function(docRef: any) {
          setSelectedEmbryo(undefined);
          setSelectedPatient(undefined);
          setPatient(undefined);
          setTimestampPatient(new Date().getTime());
          setTimestampEmbryo(new Date().getTime());
        })
        .catch(function(error: any) {
          console.error("Error updating document: ", error);
        });
    setShowModal(false);
  };

  const getActionEnabled = () => {
    switch (modalType) {
      case "createPatient":
      case "editPatient":
        return !validate("patient");
      case "createEmbryo":
      case "editEmbryo":
      case "deletePatient":
      case "deleteEmbryo":
        return true;
      default:
        return false;
    }
  };

  const showCreateEmbryo = () => {
    setModalType("createEmbryo");
    updateModalContentData("createEmbryo");
    setShowModal(true);
  };

  const showEditEmbryo = () => {
    setModalType("editEmbryo");
    updateModalContentData("editEmbryo");
    setShowModal(true);
  };

  const showDeleteEmbryo = () => {
    setModalType("deleteEmbryo");
    updateModalContentData("deleteEmbryo");
    setShowModal(true);
  };

  const showCreatePatient = () => {
    setModalType("createPatient");
    updateModalContentData("createPatient");
    setShowModal(true);
  };

  const showEditPatient = () => {
    setModalType("editPatient");
    updateModalContentData("editPatient");
    setShowModal(true);
  };

  const showDeletePatient = () => {
    setModalType("deletePatient");
    updateModalContentData("deletePatient");
    setShowModal(true);
  };

  const onSelectPatient = (patient: IPatient | undefined) => {
    setSelectedEmbryo(undefined);
    setSelectedPatient(patient);
  };

  const onSelectEmbryo = (embryo: IEmbryo) => {
    setSelectedEmbryo(embryo);
  };

  const dismissModal = () => {
    setShowModal(false);
  };

  const updateModalContentData: any = (type: ModalType) => {
    let data: any;
    switch (type) {
      case "editPatient":
        data = selectedPatient;
        break;
      case "deletePatient":
        data = { message: `Patient with history ${selectedPatient?.history} will be deleted` };
        break;
      case "createEmbryo":
        if (!embryoCount) {
          // @ts-ignore
          const clinicRef = firebase.clinics().doc(clinic?.uid);
          firebase.db
            .runTransaction(t => {
              return t.get(clinicRef).then(doc => {
                // @ts-ignore
                let newCount = +(doc.data().embryoCount) + 1;
                t.update(clinicRef, { embryoCount: newCount });
              });
            })
            .then(() => {
              // @ts-ignore
              clinicRef.get().then(snap => {
                // @ts-ignore
                const newEmbryoCount = snap.data().embryoCount;
                setEmbryoCount(newEmbryoCount);
                data = {
                  olCode: zeroPadding(newEmbryoCount, 3)
                };
              });
            });
        } else {
          data = {
            olCode: zeroPadding(embryoCount, 3)
          };
        }
        data = undefined;
        break;
      case "editEmbryo":
        data = selectedEmbryo;
        break;
      case "deleteEmbryo":
        data = { message: `Embryo with Code ${selectedEmbryo?.olCode} will be deleted` };
        break;
      default:
        data = undefined;
    }
    setModalContentData(data);
  };

  const ModalTypes: { [id: string]: IModalType } = {
    "createPatient": { title: "Create New Patient", content: Patient, action: doCreatePatient },
    "editPatient": { title: "Modify Patient", content: Patient, action: doModifyPatient },
    "createEmbryo": { title: "Create New Embryo", content: Embryo, action: doCreateEmbryo },
    "editEmbryo": { title: "Modify Embryo", content: Embryo, action: doModifyEmbryo },
    "deletePatient": { title: "Delete Patient", content: Confirm, action: doDeletePatient },
    "deleteEmbryo": { title: "Delete Embryo", content: Confirm, action: doDeleteEmbryo }
  };

  const ModalContent: React.FC<any> = ModalTypes[modalType].content;

  return (
    <div className="flex flex-col min-h-screen relative">
      <Header title={clinic?.name} />
      <div className="flex flex-grow font-Olight text-gray-700 flex-col p-4 bg-gray-100">
        <Patients
          onSelectPatient={onSelectPatient}
          selectedPatientId={selectedPatient?.uid}
          showCreatePatient={showCreatePatient}
          showEditPatient={showEditPatient}
          showDeletePatient={showDeletePatient}
          timestamp={timestampPatient}
          hasEmbryos={patientHasEmbryos}
        />
        {selectedPatient ? (
          <Embryos
            selectedPatient={selectedPatient}
            selectedEmbryo={selectedEmbryo}
            onSelectEmbryo={onSelectEmbryo}
            showCreateEmbryo={showCreateEmbryo}
            showEditEmbryo={showEditEmbryo}
            showDeleteEmbryo={showDeleteEmbryo}
            setPatientHasEmbryos={setPatientHasEmbryos}
            isPrivateData={false}
            timestamp={timestampEmbryo}
          />
        ) : (
          <div className="text-center">Select Patient</div>
        )}
        {selectedPatient?.comments && (
          <div className="p-2">
            <h3 className="font-bold text-lg">Comments</h3>
            {selectedPatient.comments}
          </div>
        )}
      </div>
      <footer className="py-1 px-2 bg-gray-600 font-Olight font-hairline text-white text-right text-xs">
        All rights reserved 2021
      </footer>
      <Modal
        visible={showModal}
        title={ModalTypes[modalType].title}
        onAction={ModalTypes[modalType].action}
        onClose={dismissModal}
        actionEnabled={getActionEnabled()}
      >
        <ModalContent data={modalContentData} />
      </Modal>
    </div>
  );
};

const rule = (authUser: object) => !!authUser;

export default withAuthorization(rule)(Dashboard);
