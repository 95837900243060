import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import Firebase, { FirebaseContext } from "../Firebase";
import firebase from "firebase/app";
import { AuthUserContext } from "../Session";
import getAgeFromDate from "../../utils/getAgeFromDate";

export enum TreatmentType {
  IVF = 0,
  ICSI = 1
}

export const formatPatients = (snapshot: firebase.firestore.QuerySnapshot) => {
  return snapshot.docs.map((patient: firebase.firestore.DocumentSnapshot) => ({
    ...(patient.data() as IPatient),
    uid: patient.id
  }));
};

// const LIMIT_LIST = 5; // TODO: Pagination
const MIN_SEARCH = 2;

const Patients: React.FC<IPatientProps> = ({
  selectedPatientId,
  onSelectPatient,
  showCreatePatient,
  showEditPatient,
  showDeletePatient,
  timestamp,
  hasEmbryos
}) => {
  const firebase: Firebase = useContext(FirebaseContext);
  const authUser: IUser = useContext(AuthUserContext);
  const [treatmentFilter, setTreatmentFilter] = useState<TreatmentType[]>([TreatmentType.IVF, TreatmentType.ICSI]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patients, setPatients] = useState<IPatient[]>([]);

  const updatePatients = () => {
    setIsLoading(true);
    onSelectPatient(undefined);
    firebase
      .clinics()
      // @ts-ignore
      .doc(authUser.clinicId)
      .get()
      // @ts-ignore
      .then(querySnapshot => {
        let query = querySnapshot.ref.collection("patients").where("treatment", "in", treatmentFilter);

        if (searchFilter.length > MIN_SEARCH) {
          query = query.where("history", "==", searchFilter);
        }

        // @ts-ignore
        query.orderBy("createdAt", "desc").get().then(data => setPatients(formatPatients(data)));
      })
      .finally(() => setIsLoading(false));
  };

  const patientsCallback = useCallback(updatePatients, [treatmentFilter, searchFilter, timestamp]);

  useEffect(() => {
    patientsCallback();
  }, [treatmentFilter, searchFilter, timestamp, patientsCallback]);

  const doFilterTreatment = (event: ChangeEvent<HTMLSelectElement>) => {
    setTreatmentFilter(event.target.value.split("|").map(Number));
  };

  const doFilterSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value.length > MIN_SEARCH ? event.target.value : "");
  };

  return (
    <div className="">
      <div>
        <h2 className="text-xl font-semibold leading-tight">Patients</h2>
      </div>

      <div className="my-2 flex sm:flex-row flex-col justify-between">
        <div className="flex flex-row mb-1 sm:mb-0 mr-1">
          <div className="inline-flex">
            <button onClick={showCreatePatient} className="btn w-24 ml-0">
              New
            </button>
            <button onClick={showEditPatient} disabled={!selectedPatientId} className="btn w-24 bg-blue-700 ml-1">
              Edit
            </button>
            <button onClick={showDeletePatient} disabled={!selectedPatientId || hasEmbryos} className="btn w-24 bg-blue-700 ml-1">
              Delete
            </button>
          </div>
        </div>

        <div className="inline-flex">
          <div className="inline-flex">
            {/*<div className="relative">
              <select className="appearance-none h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option>5</option>
                <option>10</option>
                <option>20</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>*/}

            <div className="relative">
              <select
                onChange={doFilterTreatment}
                className="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option value={[TreatmentType.IVF, TreatmentType.ICSI].join("|")}>All</option>
                <option value={TreatmentType.IVF}>{TreatmentType[TreatmentType.IVF]}</option>
                <option value={TreatmentType.ICSI}>{TreatmentType[TreatmentType.ICSI]}</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="block relative flex-grow ml-1">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
              </svg>
            </span>
            <input
              onChange={doFilterSearch}
              placeholder="Search by History"
              className="appearance-none rounded-r rounded-l rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            />
          </div>
        </div>
      </div>

      <div className="-mx-4 px-4 py-4 overflow-x-auto" style={{ maxHeight: "20rem" }}>
        <div className="inline-block min-w-full shadow rounded-none rounded-lg overflow-hidden">
          <table className="tab-data table w-full patientsRowFull">
            <thead className="table-header-group">
              <tr>
                <th>Medical Record Number</th>
                <th>Treatment</th>
                <th>Age</th>
                <th>Donor</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient, index) => (
                <tr
                  key={`patient_${index}`}
                  onClick={onSelectPatient.bind(null, patient)}
                  className={selectedPatientId === patient.uid ? "selected" : ""}
                >
                  <td>
                    <span className="collapseHead hidden">History</span>
                    {patient.history}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Treatment</span>
                    {TreatmentType[patient.treatment]}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Age</span>
                    {getAgeFromDate(patient.createdAt, patient.birthdate)}
                  </td>
                  <td>
                    <span className="collapseHead hidden">Donor</span>
                    {patient.donor ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : patients.length ? (
            <div>
              {/*<div className="px-2 py-2 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">{`Showing 1 to ${Math.min(
                LIMIT_LIST,
                patients.length
              )} of ${patients.length} Patients`}</span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>*/}
            </div>
          ) : (
            <div className="text-center">No patients</div>
          )}
        </div>
      </div>
    </div>
  );
};

interface IPatientProps {
  selectedPatientId?: string;
  onSelectPatient: (patient: IPatient | undefined) => void;
  showCreatePatient: () => void;
  showEditPatient: () => void;
  showDeletePatient: () => void;
  timestamp?: number;
  hasEmbryos: boolean;
}

export interface IPatient {
  uid: string;
  history: string;
  createdAt: firebase.firestore.Timestamp;
  birthdate: firebase.firestore.Timestamp|string;
  donor: boolean;
  treatment: number;
  comments?: string;
  clinicId: string;
}

type IRoles = { [key: string]: boolean };

export type IUser = {
  uid: string;
  email: string;
  clinicId: string;
  roles: IRoles;
};

export default Patients;
