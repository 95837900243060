import React, { ChangeEvent, useEffect, useState } from "react";
import { IPatient, TreatmentType } from "./index";
import useGlobal from "../../store";
import useForm from "../../utils/useForm";
import { IMessages } from "../../actions";

const MIN_AGE = 16;
const MAX_AGE = 55;

export interface IState {
  [key: string]: string;
}

interface IFormPatientParams {
  data: IPatient;
  onAction: (data: object) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export interface IFieldValue {
  field: string;
  value: string;
}

function getMinBirthYear() {
  const now = new Date();
  return now.getFullYear() - MAX_AGE;
}

function getMaxBirthYear() {
  const now = new Date();
  return now.getFullYear() - MIN_AGE;
}

function getBirthYears() {
  const years = [];
  let i = getMinBirthYear();
  let j = getMaxBirthYear();
  for (; i <= j; i++) {
    years.push(i);
  }
  return years;
}

export const Patient: React.FC<IFormPatientParams> = ({ data }) => {
  const [patientData, dispatch] = useForm(data);
  const [validateData, setValidateData] = useState<IMessages | null>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patient, setPatient] = useGlobal(
    state => state.patient,
    actions => actions.setPatient
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, validate] = useGlobal(
    state => state,
    actions => actions.validate
  );

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any>) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    dispatch({
      type: "update",
      param: { field: event.target.id, value }
    });
  };

  useEffect(() => {
    setPatient(patientData);
    patientData && setValidateData(validate("patient"));
  }, [validate, patientData, setPatient]);

  return (
    <form className="w-full">
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="history">
            Medical Record Number
          </label>
          <input
            value={patientData?.history || ""}
            onChange={onChange}
            className={`text-param${validateData?.history ? " error" : ""}`}
            id="history"
            type="text"
            maxLength={40}
            placeholder="Record Number"
            required
          />
          <p className="text-red-500 text-xs italic">{validateData?.history}</p>
        </div>
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="treatment">
            Treatment
          </label>
          <div className="relative">
            <select
              value={patientData ? (patientData.treatment as TreatmentType) : ""}
              onChange={onChange}
              className={`select-param${validateData?.treatment ? " error" : ""}`}
              id="treatment"
            >
              <option value={""}>Select Treatment</option>
              <option value={TreatmentType.IVF}>{TreatmentType[TreatmentType.IVF]}</option>
              <option value={TreatmentType.ICSI}>{TreatmentType[TreatmentType.ICSI]}</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <p className="text-red-500 text-xs italic">{validateData?.treatment}</p>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="birthdate">
            Birth Year
          </label>

          <select
            value={patientData?.birthdate}
            onChange={onChange}
            className={`select-param${validateData?.birthdate ? " error" : ""}`}
            id="birthdate"
            required
          >
            <option value={""}>Select Year</option>
            {getBirthYears().map((year:number, index:number) => (
            <option key={`birthdate_${index}`} value={`${year}-01-01`}>
              {year}
            </option>
            ))}
          </select>
          <p className="text-red-500 text-xs italic">{validateData?.birthdate}</p>
        </div>
        <div className="w-full sm:w-1/2 px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            <input
              checked={!!patientData?.donor}
              onChange={onChange}
              className="mr-2 leading-tight"
              type="checkbox"
              id="donor"
            />
            With Donor
          </label>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full sm:w-full px-3 mb-2 sm:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="comments">
            Comments
          </label>
          <textarea id="comments" value={patientData?.comments} onChange={onChange} className="text-param h-32" />
        </div>
      </div>
    </form>
  );
};
