import React, { useContext, useState } from "react";
import logo from "../../assets/METABOLIFE_LOGO.svg";
import Firebase, { FirebaseContext } from "../Firebase";
import { IUser } from "../Patients";
import { AuthUserContext } from "../Session";

const Header: React.FC<IHeaderProps> = ({title}) => {
  const firebase: Firebase = useContext(FirebaseContext);
  const authUser: IUser = useContext(AuthUserContext);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const toggleMenu = () => setIsOpened(!isOpened);

  return (
    <nav className="flex items-center justify-between flex-wrap bg-blue-300 p-2 pr-4 bg-gradient-header items-stretch">
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <img src={logo} className="h-32 -my-6 -mx-2" alt="logo overture life"/>
        {/*<span className="font-Omedium text-2xl tracking-tight">overture<span className="inline"> metabolomics</span></span>*/}
      </div>
      <div className="hidden">
        <button onClick={toggleMenu} className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-primary hover:border-white hover:bg-white">
          <svg className="h-3 w-3 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="block flex-grow flex items-center w-auto text-white flex-1 justify-between">
        <span className="text-primary text-2xl block font-Omedium text-center">{title}</span>

        <div className="text-sm md:flex-grow">
          {/*<a
            href="#"
            className="block mt-4 md:inline-block md:mt-0 hover:text-white mr-4"
          >
            Docs
          </a>
          <a
            href="#"
            className="block mt-4 md:inline-block md:mt-0 hover:text-white mr-4"
          >
            Examples
          </a>
          <a
            href="#"
            className="block mt-4 md:inline-block md:mt-0 hover:text-white"
          >
            Blog
          </a>*/}
        </div>
        <div className="text-right justify-between flex flex-grow-0 h-full flex-col">
          <div className="top-0 right-0 text-xs p-0 text-primary">{authUser.email}</div>
          <button
            onClick={firebase.doSignOut}
            className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-primary hover:bg-white :mt-0"
          >
            Sign Out
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;

interface IHeaderProps {
  title?: string;
  logoName?: string;
}
