import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const LIMIT_REQS = 10000;
let counter = 0;

// TODO: Create different configs .env.development and .env.production
const config = {
  appName: process.env.REACT_APP_NAME,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;

    this.auth = app.auth();
    this.db = app.firestore();
    this.timestamp = app.firestore.Timestamp;
    this.fieldValue = app.firestore.FieldValue;
  }

  // *** Auth API ***

  doSendSignInLinkToEmail = email => {
    const actionCodeSettings = {
      url: "https://metabolife.health",
      handleCodeInApp: true
    };
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  };

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.users().doc(authUser.uid)
          .get()
          .then(user => {
                // default empty roles
                const userData = user.data()
                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  clinicId: authUser.clinicId,
                  emailVerified: authUser.emailVerified,
                  providerData: authUser.providerData,
                  ...userData
                };
                if (!authUser.roles) {
                  authUser.roles = {};
                }
                next(authUser);
            })
              .catch(err => {
                console.log('Error getting user', err);
              });
      } else {
        fallback();
      }
    });

  // *** Utils ***

  limitCheck = (callback) => {
    if(++counter > LIMIT_REQS) {
      callback();
      counter = 0;
    }
    return true;
  };

  // *** Clinic API ***

  clinics = () => {
    if(this.limitCheck(this.doSignOut)){
      return this.db.collection("clinics");
    } else {
      throw Error("Requests limit reached");
    }
  };

  // *** User API ***

  users = () => {
    // console.log("Firebase:API:users");
    if(this.limitCheck(this.doSignOut)){
      return this.db.collection("users");
    } else {
      throw Error("Requests limit reached");
    }
  };

}


export default Firebase;
