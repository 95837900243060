import {IMessages} from "./index";
import {REQUIRED_FIELD, WRONG_FORMAT_PREFIX} from "../assets/strings";

export const setClinic = (store: any, clinic: object) => {
  store.setState({ clinic });
};

// @ts-ignore
export const validateClinic: IMessages = (clinic: IMessages) => {
  const messages: IMessages = {};
  if (!clinic.name) {
    messages.name = REQUIRED_FIELD;
  }
  if (!clinic.shortName) {
    messages.shortName = REQUIRED_FIELD;
  }
  if (!clinic.prefix) {
    messages.prefix = REQUIRED_FIELD;
  } else if(clinic.prefix.length < 2 || clinic.prefix.length > 4) {
    messages.prefix = WRONG_FORMAT_PREFIX;
  }
  /* if (!clinic.email) {
    messages.email = REQUIRED_FIELD;
  } else if(!(/\S+@\S+\.\S+/).test(clinic.email)) {
    messages.email = WRONG_FORMAT_EMAIL;
  }*/
  return messages;
};
