import React from "react";
import { ReactComponent as IconAdd }  from "../../assets/iconAdd.svg";
import { ReactComponent as IconDelete }  from "../../assets/iconDelete.svg";
import { ReactComponent as IconEdit } from "../../assets/iconEdit.svg";
import { ReactComponent as IconAddLight }  from "../../assets/iconAddLight.svg";
import { ReactComponent as IconDeleteLight } from "../../assets/iconDeleteLight.svg";
import { ReactComponent as IconEditLight } from "../../assets/iconEditLight.svg";
import { ReactComponent as IconClose} from "../../assets/close.svg";

const Icon: React.FC<IIcon> = ({ type, className }) => {
  let Shape: React.ElementType;
  switch (type) {
    case "add":
      Shape = IconAdd;
      break;
    case "delete":
      Shape = IconDelete;
      break;
    case "edit":
      Shape = IconEdit;
      break;
    case "addLight":
      Shape = IconAddLight;
      break;
    case "deleteLight":
      Shape = IconDeleteLight;
      break;
    case "editLight":
      Shape = IconEditLight;
      break;
    case "close":
      Shape = IconClose;
      break;
  }
  return <Shape className={className} />;
};

type IconType = "add" | "delete" | "edit" | "addLight" | "deleteLight" | "editLight" | "close";

interface IIcon {
  type: IconType;
  className: string;
}

export default Icon;
